const activeEnv =
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development";

const siteUrl = {
    development: 'https://uat.chqbook.com',
    staging: 'https://uat2.chqbook.com',
    production: 'https://www.chqbook.com'
}

module.exports = {
    siteUrl: siteUrl[activeEnv], // Site domain. Do not include a trailing slash!
    postsPerPage: 9, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)
    siteTitle: `Chqbook.com`,
    siteTitleMeta: `Open a Current Account, Apply for Loans, Khata, Insurance and Credit Cards | Chqbook.com`, // This allows an alternative site title for meta data for pages.
    siteDescriptionMeta: `India's first Neo-Bank for Small Business Owners. Open a Current Account. Manage your Khata Online. Apply Online for Personal Loan, Business Loan, Credit Cards and Insurance.`, // This allows an alternative site description for meta data for pages.

    shareImageWidth: 1000, // Change to the width of your default share image
    shareImageHeight: 523, // Change to the height of your default share image

    shortTitle: `Chqbook.com`, // Used for App manifest e.g. Mobile Home Screen
    siteIcon: `favicon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
    backgroundColor: `#ffffff`, // Used for Offline Manifest
    themeColor: `#ffffff`, // Used for Offline Manifest
}
